@keyframes videoAnimation {
    0% {
        padding-top: 56.25%;
    }
    100% {
        padding-top: 100%;
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 #fff4, 0 0 0 0 #fff4;
    }

    80% {
        box-shadow: 0 0 0 15px #fff0, 0 0 0 30px #fff0;
    }

    100% {
        box-shadow: 0 0 0 0 #fff0, 0 0 0 0 #fff0;
    }
}

.vjs-custom-styles {
    position: relative;
    top: 0;
    max-width: 100%;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vjs-custom-styles.vjs-fluid:not(.vjs-audio-only-mode)[id^='vjs_video'] {
    padding-top: 56.25%;
}

.vjs-custom-styles.video-js .vjs-big-play-button {
    background-color: white;
    color: white;
    font-size: 4em;

    animation-name: ripple;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.vjs-custom-styles .vjs-big-play-button .vjs-icon-placeholder::before {
    font-size: 64px;
    line-height: 64px;
}

@media (max-width: 600px) {
    .vjs-custom-styles.video-js .vjs-big-play-button {
        font-size: 3em;
    }

    .vjs-custom-styles .vjs-big-play-button .vjs-icon-placeholder::before {
        line-height: 48px;
        font-size: 48px;
    }
}

.vjs-custom-styles.video-js:hover .vjs-big-play-button,
.vjs-custom-styles.video-js .vjs-big-play-button:focus {
    opacity: 0.9;
}

.vjs-custom-styles .vjs-poster img {
    object-fit: cover;
    -o-object-fit: cover;
}

.vjs-custom-ended {
    transition: all 0.3s ease-in;
    filter: brightness(0.5);
    visibility: visible
}

.vjs-animation.vjs-fluid:not(.vjs-audio-only-mode)[id^='vjs_video'] {
    animation-name: videoAnimation;
    animation-duration: 300ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}





